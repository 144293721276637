<template>
  <div>
    <div class="header">
      <svg-icon @click="back" class="svg" iconClass="right"></svg-icon>
    </div>
    <createPlanMap ref="createPlanMap" :citys="mapCityArr"></createPlanMap>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import createPlanMap from './children/createPlanMap.vue';
import { mapActions, mapState } from "vuex";
import router from '@/router'

export default {
  name: '',
  components: {
    createPlanMap
  },
  // 定义属性
  data() {
    return {
      mapCityArr: []
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    ...mapActions('collection', ['JUMP_TO_DEFAULT_LINK']),
    init() {
      this.$refs.createPlanMap.initMap(true)
    },
    //返回上一个页面
    back() {
      router.go(-1)
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    window.addEventListener("popstate", (e) => {
      this.JUMP_TO_DEFAULT_LINK()
    }, false);
    this.$nextTick(() => {
      this.mapCityArr = this.$route.params.citys
      this.init()
    })
  },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style lang="scss" scoped>
.header {
  padding-left: 0.3rem;
  height: 0.7rem;
  background-color: #fff;
  display: flex;
  align-items: center;

  .svg {
    transform: rotate(180deg);
    height: 0.45rem;
    width: 0.45rem;
  }
}
</style>