var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("svg-icon", {
            staticClass: "svg",
            attrs: { iconClass: "right" },
            on: { click: _vm.back },
          }),
        ],
        1
      ),
      _c("createPlanMap", {
        ref: "createPlanMap",
        attrs: { citys: _vm.mapCityArr },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }